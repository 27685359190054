import React from 'react'

const Navbar = () => {
    return (
        <nav className="navbar bg-dark navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <img src="https://img.icons8.com/?size=512&id=15130&format=png" alt="Logo" width={40} className="d-inline-block align-text-top"/>
                        &nbsp;TROCA-ÓLEO
                </a>
            </div>
        </nav>
    )
}

export default Navbar