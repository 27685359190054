import api from "./api";

const get = async () => {
    try {
        const { data } = await api.get("/api/configuracao");
        return data;
    } catch (error) {
        return error.message;
    }
}

const post = async (model) => {
    try {
        const { data } = await api.post("/api/configuracao", model);

        return data;
    } catch (error) {
        return error.message;
    }
}

export const ConfiguracaoService = {
    get,
    post
}