import React, { useState } from 'react';
import { TrocaOleoService } from '../../services/TrocaOleoService';
import { ConfiguracaoService } from '../../services/ConfiguracaoService';

const Home = () => {
    const token = localStorage.getItem("token");
    if(!token) window.location.href = "/login";

    const [config, setConfig] = useState({});
    const [trocasDeOleo, setTrocasDeOleo] = useState([]);
    const [erro, setErro] = useState("");
    const [loading, setLoading] = useState(true);
    const [trocarACada, setTrocarACada] = useState(0);

    const get = () => {
        ConfiguracaoService.get().then(resp => {
            if (resp instanceof Object) {
                setConfig(resp);

                setTrocarACada(resp.trocarACada);
            } else {
                setErro(resp);
            }
        });

        TrocaOleoService.get().then(resp => {
            if (resp instanceof Array) {
                setTrocasDeOleo(resp);
            } else {
                setErro(resp);
            }
        });

        setLoading(false);
    }

    const remove = (id) => {
        var confirm = window.confirm("Tem certeza?");

        if (confirm) {
            TrocaOleoService.remove(id).then(resp =>{
                alert(resp);
            });
        }
    }
    const salvarConfig = () => {
        ConfiguracaoService.post({ id: config.id, trocarACada: trocarACada }).then(resp => {
            window.alert(resp);
        });
    }

    const formatarData = (data) => {
        const partes = data.split('T')[0].split('-');
        const dia = partes[2];
        const mes = partes[1];
        const ano = partes[0];

        return dia + '/' + mes + '/' + ano;
    }

    if (loading) {
        get();
    }

    return (
        <>
            <p className='text-center'>Trocar a cada <strong>{config?.trocarACada} Km</strong> <button className='btn btn-sm btn-outline-primary' data-bs-toggle="modal" data-bs-target="#modalAlterarConfig">Alterar</button></p>
            {/* <div className='text-center alert alert-warning'>Faltam {calculoParaProximaTroca()}km para a próxima troca</div> */}

            <p>Para a próxima troca, é recomendável que o painel esteja em <strong>{trocasDeOleo[0]?.kmPainel + config.trocarACada}Km</strong></p>
            <small className='text-center d-block p-0 mb-1'>Exibindo {trocasDeOleo?.length} resultados</small>

            {erro && (
                <div className='alert alert-danger text-center'>
                    {erro}
                </div>
            )}
            {trocasDeOleo && trocasDeOleo?.map((t, idx) =>
                <div key={t.id} className="card mb-2 border-dark">
                    <div className="card-body d-flex justify-content-between align-items-center">
                        <small><i className="fa-solid fa-calendar-days"></i> Data: <strong>{formatarData(t.data)}</strong></small>
                        <small><i className="fa-solid fa-desktop"></i> Painel: <strong>{t.kmPainel}km</strong></small>
                    </div>
                    <div className='card-footer d-flex justify-content-between align-items-center'>
                        <small className={(t?.kmRodados + 100) > config?.trocarACada ? 'text-danger' : 'text-success'}><i className="fa-solid fa-motorcycle"></i> <strong>{t.kmRodados}</strong>km rodados</small>
                        <div className='d-flex justify-content-end'>
                            <button className={`btn btn-sm btn-outline-primary me-1 ${idx == 0 ? '' : 'd-none'}`}  onClick={() => window.location.href = `/nova-troca/${t.id}`}><i className="fa-solid fa-pen-to-square"></i></button>
                            <button className={`btn btn-sm btn-outline-danger ${idx == 0 ? '' : 'd-none'}`} onClick={() => { remove(t.id) }}><i className="fa-solid fa-trash"></i></button>
                        </div>
                    </div>
                </div>
            )}

            <div className="modal fade" id="modalAlterarConfig" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <input className='form-control form-control-sm' value={trocarACada ?? 0} onChange={(e) => setTrocarACada(e.target.value)} />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={salvarConfig}>Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;