import React from 'react'

const Footer = () => {
    return (
        <div className="container-fluid p-3">
            <footer className='text-center'>
                <p>&copy; {new Date().getFullYear()} - Daniel Pereira Sanches</p>
            </footer>
        </div>
    )
}

export default Footer