import api from "./api";

const login = async (email, senha) => {
    try {
        const model = {email, senha};
        const { data } = await api.post("/api/Account", model);

        return data;
    } catch (error) {
        return error.message;
    }
}

export const AccountService = {
    login
} 