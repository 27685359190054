import api from "./api";

const post = async (model) => {
    try {
        const { data } = await api.post("/api/TrocaOleo", model);
        return data;
    } catch (error) {
        alert(error);
    }
}

const get = async (id = 0) => {
    try {
        const { data } = await api.get("/api/TrocaOleo/" + id);
        return data;
    } catch (error) {
        return error.message;
    }
}

const remove = async (id) =>{
    try {
        const { data } = await api.delete("/api/TrocaOleo/" + id);
        return data;
    } catch (error) {
        return error.message;
    }
}

export const TrocaOleoService = {
    post,
    get,
    remove
}