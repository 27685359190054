import React, { useState } from 'react'
import { TrocaOleoService } from '../../services/TrocaOleoService';

const NovaTroca = () => {
  const [data, setData] = useState("");
  const [kmPainel, setKmPainel] = useState(0);
  const [loading, setLoading] = useState(true);

  let model = { id: 0, data, kmPainel, kmRodados: 0 };

  function getIdFromUrl(url) {
    url = url.replace(/\?$/, '');

    var parts = url.split('/');

    return parts[2];
  }

  const id = getIdFromUrl(window.location.pathname);

  if (id && loading) {
    TrocaOleoService.get(id).then(resp => {
      model = resp;
      setData(model.data);
      setKmPainel(model.kmPainel);
    });

    setLoading(false);
  }

  const salvar = () => {
    try {
      model.id = id;
      TrocaOleoService.post(model).then(resp => {
        alert(resp);

        window.location.href = "/";
      });
    } catch (error) {
      alert(error);
    }

  }
  return (
    <>
      <h2 className='text-center mb-2'>Nova troca</h2>
      <form>
        <div className="form-group mb-2">
          <label htmlFor="data">Data: </label>
          <input type="date" className="form-control" id="data" placeholder="dd/mm/yyyy" value={data.split("T")[0]} onChange={(e) => setData(e.target.value)} />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="km">Km Painel: </label>
          <input type="number" className="form-control" id="km" placeholder="Digite o valor do km no painel" value={kmPainel} onChange={(e) => setKmPainel(e.target.value)} />
        </div>
        <button type="button" className="btn btn-primary my-2 w-100" onClick={salvar}>Salvar</button>
      </form>
    </>
  )
}

export default NovaTroca