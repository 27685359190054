import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import Home from './pages/Home/index';
import NovaTroca from "./pages/NovaTroca";
import Footer from "./components/footer";
import Login from "./pages/Login";

function App() {
  const novaTroca = () => {
    window.location.href = "/nova-troca";
  }


  return (
    <>
      <BrowserRouter>
        <Navbar />

        <div className="container position-relative my-2">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/nova-troca" element={<NovaTroca />} />
            <Route path="/nova-troca/:id" element={<NovaTroca />} />
          </Routes>

          <button onClick={novaTroca} className='btn btn-primary rounded-circle btn-nova-troca'><i className="fa-solid fa-plus"></i></button>
        </div>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
