import React, { useState } from 'react';
import "./login.css";
import { AccountService } from '../../services/AccountService';

const Login = () => {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const autenticar = (e) => {
        e.preventDefault();
        AccountService.login(email, senha).then(resp => {
            if(resp == '0ce87fd8954aca77ef28262bd342815cf84c7de918f8552240c4fad8d43fc416'){
                localStorage.setItem("token", JSON.stringify(resp));
                window.location.href = "/"
                return;
            }

            alert("Email ou senha incorretos");
        });
    }

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="login-container">
                    <h2>Login</h2>
                    <form>
                        <div className="form-group mb-2">
                            <input type="text" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group mb-2">
                            <input type="password" className="form-control" placeholder="Senha" value={senha} onChange={(e) => setSenha(e.target.value)} />
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={autenticar}>Entrar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;