import axios from "axios";

const API_URL = "https://troca-oleo.api.danielsanchesdev.com.br";
// const API_URL = "http://127.0.0.1:7273";

let token = localStorage.getItem("token");

let hashSenha = "";
if (token) {
    token = JSON.parse(token);
    hashSenha = token;
}

export default axios.create({
    baseURL: API_URL,
    headers: {
        "hashSenha": hashSenha
    }
});